import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import css from './ListingPage.module.css';

const SectionLocationDescriptionMaybe = props => {
  const { publicData, intl } = props;

  if (
    !publicData ||
    !publicData.locationAdditional ||
    !publicData.locationAdditional.locationDescription
  ) {
    return null;
  }

  return (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.locationDescriptionTitle" />
      </Heading>
      <span className={css.details}>{publicData.locationAdditional.locationDescription}</span>
    </div>
  );
};

export default SectionLocationDescriptionMaybe;
