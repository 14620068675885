import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import css from './ListingPage.module.css';

const SectionMeetingInstructionsMaybe = props => {
  const { publicData, intl } = props;

  if (
    !publicData ||
    !publicData.locationAdditional ||
    !publicData.locationAdditional.meetingInstructions
  ) {
    return null;
  }

  return (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.meetingInstructionsTitle" />
      </Heading>
      <span className={css.details}>{publicData.locationAdditional.meetingInstructions}</span>
    </div>
  );
};

export default SectionMeetingInstructionsMaybe;
