import React from 'react';
import { H2, H4, Reviews } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionReviews = props => {
  const { reviews, fetchReviewsError } = props;

  return (
    <section id="reviews" className={css.sectionReviews}>
      <H4 as="h2" className={css.sectionReviewsTitle}>
        <FormattedMessage id="ListingPage.reviewsTitle" />
      </H4>
      <div className={css.sectionReviewsSubtitle}>
        <FormattedMessage id="ListingPage.reviewsCount" values={{ count: reviews.length }} />
      </div>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}
      <div>
        <Reviews reviews={reviews} className={css.sectionReviewsContainer} />
      </div>
    </section>
  );
};

export default SectionReviews;
