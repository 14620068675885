import React from 'react';
import { Zap, User, TriangleAlert } from 'lucide-react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import css from './ListingPage.module.css';

const skillLevelsOrder = ['beginner', 'intermediate', 'advanced', 'professional'];
function skillLevelSort(a, b) {
  const indexA = skillLevelsOrder.indexOf(a);
  const indexB = skillLevelsOrder.indexOf(b);
  return indexA - indexB;
}
function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Shows the custom requirements including min and max age, skill levels and any additional requirements
const SectionRequirementsMaybe = props => {
  const { publicData, intl } = props;
  if (!publicData || !publicData.requirements) {
    return null;
  }

  const { maxAge, minAge, skillLevels, additionalRequirements } = publicData.requirements;

  if (!maxAge || !minAge || !skillLevels) {
    return null;
  }

  const sortedSkillLevelsString = skillLevels
    .sort(skillLevelSort)
    .map(capitalize)
    .join(', ');

  const agesString = minAge + '-' + maxAge + (maxAge === 50 ? '+' : '');
  return (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.requirementsTitle" />
      </Heading>
      <ul className={css.details}>
        {sortedSkillLevelsString ? (
          <li className={css.item}>
            <span className={css.iconWrapper}>
              <Zap style={{ fill: '#fff' }} />
            </span>
            <div className={css.labelWrapper}>
              <span>{sortedSkillLevelsString}</span>
            </div>
          </li>
        ) : null}

        <li className={css.item}>
          <span className={css.iconWrapper}>
            <User style={{ fill: '#fff' }} />
          </span>
          <div className={css.labelWrapper}>
            <span>{agesString} years old</span>
          </div>
        </li>
        {additionalRequirements ? (
          <li className={css.item}>
            <span className={css.iconWrapper}>
              <TriangleAlert style={{ fill: '#fff' }} />
            </span>
            <div className={css.labelWrapper}>
              {additionalRequirements.split('\n').map((line, index) => (
                <div key={index}>
                  <span>{line}</span>
                  <br />
                </div>
              ))}
            </div>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default SectionRequirementsMaybe;
