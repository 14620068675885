import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, Accordion } from '../../components';

import css from './ListingPage.module.css';

const SectionAdditionalInfoMaybe = props => {
  const { publicData, intl } = props;

  if (!publicData || !publicData.additionalInfo) {
    return null;
  }

  const { additionalInfo } = publicData;

  if (
    !additionalInfo.whatToBring &&
    !additionalInfo.whatToExpect &&
    !additionalInfo.whatsIncluded
  ) {
    return null;
  }

  const accordionItems = [];
  if (additionalInfo.whatToExpect) {
    accordionItems.push({
      header: 'What can you expect?',
      content: additionalInfo.whatToExpect,
    });
  }

  if (additionalInfo.whatToBring) {
    accordionItems.push({ header: 'What should you bring?', content: additionalInfo.whatToBring });
  }

  if (additionalInfo.whatsIncluded) {
    accordionItems.push({
      header: "What's included?",
      content: additionalInfo.whatsIncluded,
    });
  }
  return (
    <div className={css.sectionDetails}>
      <Accordion items={accordionItems} />
    </div>
  );
};

export default SectionAdditionalInfoMaybe;
